import React, { useState } from 'react'
import PropTypes from 'prop-types'
import emailjs from 'emailjs-com'
import ReCAPTCHA from 'react-google-recaptcha'

function ContactForm(props) {
  const [messageSent, setMessageSent] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const allowSubmit = () => {
    setDisabled(false)
  }

  const sendForm = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'smtp_server',
        'template_NHmzWslw',
        e.target,
        'user_ClcCq44FMQigHKwia32oE'
      )
      .then(
        result => {
          document.getElementById('contact-form').reset()
          setMessageSent(true)
          setTimeout(() => {
            setMessageSent(false)
          }, 3000)
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <article
      id="contact"
      className={`${props.article === 'contact' ? 'active' : ''} ${
        props.articleTimeout ? 'timeout' : ''
      }`}
      style={{ display: 'none' }}
    >
      <h2 className="major">Kontakt</h2>
      <form onSubmit={sendForm} id="contact-form">
        <div className="field half first">
          <label htmlFor="name">Imię i nazwisko</label>
          <input type="text" name="name" id="name" required />
        </div>
        <div className="field half">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          />
        </div>
        <div className="field half first">
          <label htmlFor="phone">Telefon</label>
          <input
            type="text"
            name="phone"
            id="phone"
            required
            pattern="[0-9]{9}"
          />
        </div>
        <div className="field half">
          <label htmlFor="topic">Temat</label>
          <select name="topic" id="topic" required>
            <option value="">Wybierz temat</option>
            <option value="Sprzedaż nieruchomości">Sprzedaż nieruchomości</option>
            <option value="Sprzedaż udziałów w nieruchomości">
              Sprzedaż udziałów w nieruchomości
            </option>
            <option value="Sprzedaż działki">Sprzedaż działki</option>
            <option value="Sprzedaż lokalu">Sprzedaż lokalu</option>
            <option value="Inne">Inne</option>
          </select>
        </div>
        <div className="field">
          <label htmlFor="message">Wiadomość</label>
          <textarea name="message" id="message" rows="4" required />
        </div>
        <ReCAPTCHA
          sitekey="6LfsKa8ZAAAAAOHWAtNUAkuzsegJn3iQjk3wVHyK"
          onChange={allowSubmit}
          theme="dark"
        />
        <ul className="actions">
          <li>
            <input
              type="submit"
              value="Wyślij wiadomość"
              className="special"
              disabled={disabled}
            />
            {messageSent && (
              <div style={{ color: 'green' }}>Wiadomość wysłana.</div>
            )}
          </li>
          <li>
            <input type="reset" value="Wyczyść" />
          </li>
        </ul>
      </form>
      <ul className="icons" style={{display: 'none'}}>
        <li>
          <a href="" className="icon fa-twitter">
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a href="" className="icon fa-facebook">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="" className="icon fa-instagram">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a href="" className="icon fa-github">
            <span className="label">GitHub</span>
          </a>
        </li>
      </ul>
      {props.close}
    </article>
  )
}

ContactForm.propTypes = {
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  close: PropTypes.any,
}

export default ContactForm
