import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import arrow from '../images/white-arrow.png'
import ContactForm from './ContactForm'

class Main extends React.Component {
  goToContactForm() {
    this.props.setArticle('contact')
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">O nas</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p className="article--content">
            Zajmujemy się skupem nieruchomości w Wejherowie i okolicach ( + 40
            km), a także w Trójmieście i miejscowościach nadmorskich. Zapewniamy
            fachową pomoc i szybką wycenę. Pomagamy w pozbyciu się nieruchomości
            w celu rozwiązania problemów np. uniknięcia licytacji komorniczej,
            windykacji czy eksmisji. Skupujemy nieruchomości niezależnie od
            stanu prawnego, a także udziały w tych nieruchomościach.
          </p>
          <p className="article--content">
            Potrzebujesz gotówki ? Chcesz sprzedać szybko mieszkanie? Nie marnuj
            czasu na szukanie kupca.{' '}
            <span
              className="article--contact-link"
              onClick={() => this.goToContactForm()}
            >
              Skontaktuj
            </span>{' '}
            się z nami.
          </p>
          {close}
        </article>

        <article
          id="offer"
          className={`${this.props.article === 'offer' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Oferta</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <ol>
            <li>
              <h4>Skup nieruchomości – mieszkania, domy</h4>
              <p className="article--content">
                Skupujemy mieszkania oraz domy – z lokatorami, zadłużone, z
                hipoteką, z komornikiem oraz wszelkie które maja trudna sytuacje
                prawna lub finansową. W szczególności interesują nas te do
                generalnego remontu – zaniedbane i zniszczone. Oferujemy szybka
                wycenę, pomagamy znaleźć jak najkorzystniejsze rozwiązanie,
                umożliwiając jak najszybszą sprzedaż
              </p>
            </li>
            <li>
              <h4> Skup udziałów w nieruchomościach</h4>
              <p className="article--content">
                Skupujemy udziały w nieruchomościach współwłasnościowych oraz
                częściach ułamkowych mieszkań, domów, działek, domów
                wielorodzinnych. Otrzymałeś część nieruchomości w spadku? Nie
                możesz porozumieć się ze współwłaścicielami? Skontaktuj się z
                nami – odkupimy Twoje udziały, a Ty otrzymasz gotówkę od ręki
              </p>
            </li>
            <li>
              <h4>Skup działek</h4>
              <p className="article--content">
                Skupujemy działki budowlane, inwestycyjne, przemysłowe i
                siedliskowe niezależnie od stanu prawnego, a także udziały w
                tych działkach
              </p>
            </li>
            <li>
              <h4>Skup lokali oraz garaży</h4>
              <p className="article--content">
                Skupujemy lokale bez najemców oraz lokale wynajęte. Jesteśmy
                zainteresowani także garażami. Oferujemy szybką wycenę i pomoc w
                załatwieniu formalności
              </p>
            </li>
          </ol>
          {close}
        </article>

        <article
          id="procedure"
          className={`${this.props.article === 'procedure' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Procedura</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <h3 className="article--content">Jak działamy? Krok po kroku:</h3>
          <ul className="procedure-stages__container">
            <li className="red">
              <h4>Kontakt</h4>
              <p className="article--content">
                telefoniczny, email lub poprzez formularz - omówienie stanu
                nieruchomości, umówienie terminu oględzin
              </p>
            </li>
            <div className="connector">
              <img src={arrow} alt="arrow"/>
            </div>
            <li className="blue">
              <h4>Wycena</h4>
              <p className="article--content">
                wycena nieruchomości i propozycja ceny
              </p>
            </li>
            <div className="connector">
              <img src={arrow} alt="arrow"/>
            </div>
            <li className="yellow">
              <h4>Sfinalizowanie zakupu u notariusza</h4>
            </li>
            <div className="connector">
              <img src={arrow} alt="arrow"/>
            </div>
            <li className="green">
              <h4>Wypłata środków</h4>
            </li>
          </ul>
          {close}
        </article>

        <ContactForm
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          close={close}
        />
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  setArticleVisible: PropTypes.func,
  onOpenArticle: PropTypes.func,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
