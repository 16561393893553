import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-home" />
    </div>
    <div className="content">
      <div className="inner">
        <h1 className="logo__company-name">PROSPERO</h1>
        <div className="logo__divider"/>
        <p className="logo__company_subname">
          Skup nieruchomości
        </p>
        <div className="logo__divider logo__divider--next_to_phone"/>
        <p className="logo__phone_number">
          tel: 518 847 906
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            O nas
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('offer')
            }}
          >
            Oferta
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('procedure')
            }}
          >
            Procedura
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Kontakt
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
